import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

const sentryDsnUrl = process.env.SENTRY_FRONTEND_DSN_URL || "";
const sentrySampleRate = process.env.SENTRY_SAMPLE_RATE || 0.1;

Sentry.init({
  dsn: sentryDsnUrl,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.0,
  sampleRate: sentrySampleRate,
});
